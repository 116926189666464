

import { lazy } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { AppRoutesModel, appRoutes } from './core/appRoutes';
import { getStoredValue } from './core/useStorage';
import { checkPermission, userRole } from './core/permissionService';

const ProtectedLayout = lazy(() => import('./layout/ProtectedLayout'));
const Home = lazy(() => import('./pages/protected/Dashboard'));
const Account = lazy(() => import('./pages/protected/account/UserAccount'));

const BottlesList = lazy(() => import('./pages/protected/bottles/BottlesList'));
const BottleDetails = lazy(() => import('./pages/protected/bottles/BottleDetails'));
const NewBottle = lazy(() => import('./pages/protected/bottles/NewBottle'));

const ClientsList = lazy(() => import('./pages/protected/clients/ClientsList'));
const ClientsDetails = lazy(() => import('./pages/protected/clients/ClientsDetails'));
const NewClient = lazy(() => import('./pages/protected/clients/NewClient'));

const Layout = lazy(() => import('./pages/protected/layout/Layout'));
const LayoutDetails = lazy(() => import('./pages/protected/layout/LayoutDetails'));

const MachineDetails = lazy(() => import('./pages/protected/Machine/MachineDetails'));
const NewMachine = lazy(() => import('./pages/protected/Machine/NewMachine'));
const MachinesList = lazy(() => import('./pages/protected/Machine/MachinesList'));
const MachineGroups = lazy(() => import('./pages/protected/Machine/MachineGroups'));
const MachineUsers = lazy(() => import('./pages/protected/Machine/MachineUsers'));
const MachineGroupDetails = lazy(() => import('./pages/protected/Machine/MachineGroupDetails'));
const MachineBottles = lazy(() => import('./pages/protected/Machine/MachineBottles'));
const MachineBottleDetails = lazy(() => import('./pages/protected/Machine/MachineBottleDetails'));
const LoadsList = lazy(() => import('./pages/protected/load/LoadsList'));

const NotificationList = lazy(() => import('./pages/protected/notifications/NotificationList'));
const NotificationDetails = lazy(() => import('./pages/protected/notifications/NotificationDetails'));

const PermissionGroups = lazy(() => import('./pages/protected/userGroup/UserGroups'));
const Permissions = lazy(() => import('./pages/protected/userGroup/Permissions'));
const PermissionGroupDetails = lazy(() => import('./pages/protected/userGroup/UserGroupDetails'));
const UsersPermission = lazy(() => import('./pages/protected/userGroup/UsersPermission'));

const PriceList = lazy(() => import('./pages/protected/price/PriceList'));
const PriceDetails = lazy(() => import('./pages/protected/price/PriceDetails'));
const PenaltyParams = lazy(() => import('./pages/protected/price/PenaltyParams'));
const Penalties = lazy(() => import('./pages/protected/price/Penalties'));
const PenaltyDetails = lazy(() => import('./pages/protected/price/PenaltyDetails'));

const PaymentMethods = lazy(() => import('./pages/protected/configurations/PaymentMethods'));
const NewPaymentMethod = lazy(() => import('./pages/protected/configurations//NewPaymentMethod'));
const PaymentMethodDetails = lazy(() => import('./pages/protected/configurations//PaymentMethodDetails'));

const TransactionsList = lazy(() => import('./pages/protected/transactions/TransactionsList'));
const TransactionDetails = lazy(() => import('./pages/protected/transactions/TransactionDetails'));

const UsersList = lazy(() => import('./pages/protected/users/UsersList'));
const UserDetails = lazy(() => import('./pages/protected/users/UserDetails'));
const NewUser = lazy(() => import('./pages/protected/users/NewUser'));

const AuthLayout = lazy(() => import('./layout/AuthLayout'));
const Login = lazy(() => import('./pages/public/Login'));
const ForgotPassword = lazy(() => import('./pages/public/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/public/ResetPassword'));
const NotFoundPage = lazy(() => import('./pages/public/NotFoundPage'));

// Checking token stored in the browser's local storage.
const isLoggedIn = getStoredValue('token') ?? null;
// Retrieving paths from a single file, appRoutes, for efficient customisations in the future.
const getPaths = (key: string) => appRoutes.find((item: AppRoutesModel) => { return item.routeName === key})?.path;

const router = createBrowserRouter([
    {
        element: <ProtectedLayout />,
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                path: getPaths('home'),
                element: isLoggedIn && isLoggedIn !== null && isLoggedIn !== '' ? <Home /> : <Navigate to={getPaths('login')??''}  replace />
            },
            {
                index: true,
                path: getPaths('account'),
                element: isLoggedIn && isLoggedIn !== null && isLoggedIn !== '' ? <Account /> : <Navigate to={getPaths('login')??''}  replace />
            },
            {
                path: getPaths('notifications'),
                element: isLoggedIn && isLoggedIn !== null && isLoggedIn !== '' ? <Outlet /> : <Navigate to={getPaths('login')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('notifications'),
                        element: isLoggedIn && isLoggedIn !== null && isLoggedIn !== '' ? <NotificationList /> : <Navigate to={getPaths('login')??''}  replace />   
                    },
                    {
                        path: getPaths('notificationDetails'),
                        element: isLoggedIn && isLoggedIn !== null && isLoggedIn !== '' ? <NotificationDetails /> : <Navigate to={getPaths('notifications')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('clients'),
                element: userRole === 'superuser' ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('clients'),
                        element: userRole === 'superuser' ? <ClientsList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('clientDetails'),
                        element: userRole === 'superuser' ? <ClientsDetails /> : <Navigate to={getPaths('clients')??''}  replace />
                    },
                    {
                        path: getPaths('newClient'),
                        element: userRole === 'superuser' ? <NewClient /> : <Navigate to={getPaths('clients')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('users'),
                element: checkPermission('users') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('users'),
                        element: checkPermission('users') ? <UsersList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('userDetails'),
                        element: checkPermission('userDetails') ? <UserDetails /> : <Navigate to={getPaths('users')??''}  replace />
                    },
                    {
                        path: getPaths('newUser'),
                        element: checkPermission('newUser') ? <NewUser /> : <Navigate to={getPaths('users')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('permissionGroups'),
                element: checkPermission('permissionGroups') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('permissionGroups'),
                        element: checkPermission('permissionGroups') ? <PermissionGroups /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('permissions'),
                        element: checkPermission('permissions') ? <Permissions /> : <Navigate to={getPaths('permissionGroups')??''}  replace />
                    },
                    {
                        path: getPaths('permissionGroupDetails'),
                        element: checkPermission('permissionGroupDetails') ? <PermissionGroupDetails /> : <Navigate to={getPaths('permissionGroups')??''}  replace />
                    },
                    {
                        path: getPaths('usersPermission'),
                        element: checkPermission('usersPermission') ? <UsersPermission /> : <Navigate to={getPaths('permissionGroups')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('transactions'),
                element: checkPermission('transactions') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('transactions'),
                        element: checkPermission('transactions') ? <TransactionsList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('transactionDetails'),
                        element: checkPermission('transactionDetails') ? <TransactionDetails /> : <Navigate to={getPaths('transactions')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('layout'),
                element: checkPermission('layout') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('layout'),
                        element: checkPermission('layout') ? <Layout /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('layoutDetails'),
                        element: checkPermission('layoutDetails') ? <LayoutDetails /> : <Navigate to={getPaths('layout')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('machines'),
                element: checkPermission('machines') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('machines'),
                        element: checkPermission('machines') ? <MachinesList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('machineDetails'),
                        element: checkPermission('machineDetails') ? <MachineDetails /> : <Navigate to={getPaths('machines')??''}  replace />
                    },
                    {
                        path: getPaths('newMachine'),
                        element: checkPermission('newMachine') ? <NewMachine /> : <Navigate to={getPaths('machines')??''}  replace />
                    },
                    {
                        path: getPaths('machineUsers'),
                        element: checkPermission('machineUsers') ? <MachineUsers /> : <Navigate to={getPaths('machines')??''}  replace />
                    },
                    {
                        path: getPaths('loadsList'),
                        element: checkPermission('loadsList') ? <LoadsList /> : <Navigate to={getPaths('machines')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('machineGroups'),
                element: checkPermission('machineGroups') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('machineGroups'),
                        element: checkPermission('machineGroups') ? <MachineGroups /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('machineGroupDetails'),
                        element: checkPermission('machineGroupDetails') ? <Outlet /> : <Navigate to={getPaths('machineGroups')??''}  replace />,
                        children: [
                            {
                                index: true,
                                path: getPaths('machineGroupDetails'),
                                element: checkPermission('machineGroupDetails') ? <MachineGroupDetails /> : <Navigate to={getPaths('machineGroups') ?? ''} replace />
                            },
                            {
                                path: getPaths('groupBottles'),
                                element: checkPermission('groupBottles') ? <Outlet /> : <Navigate to={getPaths('machineGroups')??''}  replace />,
                                children: [
                                    {
                                        index: true,
                                        path: getPaths('groupBottles'),
                                        element: checkPermission('groupBottles') ? <MachineBottles /> : <Navigate to={getPaths('machineGroups') ?? ''} replace />
                                    },
                                    {
                                        path: getPaths('groupBottleDetails'),
                                        element: checkPermission('groupBottleDetails') ? <MachineBottleDetails /> : <Navigate to={getPaths('machineBottles')??''}  replace />
                                    }
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                path: getPaths('bottlesList'),
                element: checkPermission('bottlesList') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('bottlesList'),
                        element: checkPermission('bottlesList') ? <BottlesList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('bottleDetails'),
                        element: checkPermission('bottleDetails') ? <BottleDetails /> : <Navigate to={getPaths('bottlesList')??''}  replace />
                    },
                    {
                        path: getPaths('newBottle'),
                        element: checkPermission('newBottle') ? <NewBottle /> : <Navigate to={getPaths('bottlesList')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('priceList'),
                element: checkPermission('priceList') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('priceList'),
                        element: checkPermission('priceList') ? <PriceList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('priceDetails'),
                        element: checkPermission('priceDetails') ? <PriceDetails /> : <Navigate to={getPaths('priceList')??''}  replace />
                    },
                ]
            },
            {
                path: getPaths('penaltyParams'),
                element: checkPermission('penaltyParams') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('penaltyParams'),
                        element: checkPermission('penaltyParams') ? <PenaltyParams /> : <Navigate to={getPaths('home')??''}  replace />,
                    }
                ]
            },
            {
                path: getPaths('penaltyList'),
                element: checkPermission('penaltyList') ? <Outlet /> : <Navigate to={getPaths('penaltyParams') ?? ''} replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('penaltyList'),
                        element: checkPermission('penaltyList') ? <Penalties /> : <Navigate to={getPaths('home')??''}  replace />,
                    },
                    {
                        path: getPaths('penaltyDetails'),
                        element: checkPermission('penaltyDetails') ? <PenaltyDetails /> : <Navigate to={getPaths('penaltyList')??''} replace />,
                    }
                ]
            },
            {
                path: getPaths('paymentMethods'),
                element: checkPermission('paymentMethods') ? <Outlet /> : <Navigate to={getPaths('home') ?? ''} replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('paymentMethods'),
                        element: checkPermission('paymentMethods') ? <PaymentMethods /> : <Navigate to={getPaths('home')??''}  replace />,
                    },
                    {
                        path: getPaths('paymentMethodDetails'),
                        element: checkPermission('paymentMethodDetails') ? <PaymentMethodDetails /> : <Navigate to={getPaths('paymentMethods')??''} replace />,
                    },
                    {
                        path: getPaths('newPaymentMethod'),
                        element: checkPermission('newPaymentMethod') ? <NewPaymentMethod /> : <Navigate to={getPaths('paymentMethods')??''} replace />,
                    }
                ]
            }
        ]
    },
    {
        path: getPaths('login'),
        element: <AuthLayout />,
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                path: getPaths('login'),
                element: <Login />
            },
            {
                path: getPaths('forgotPassword'),
                element: <ForgotPassword />
            },
            {
                path: getPaths('resetPassword'),
                element: <ResetPassword />
            }
        ]
    },
]);

export default router