// import { socketPath } from '../axiosInstance';
import { getStoredValue } from './useStorage';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { createContext, useContext, ReactNode, useState, useEffect, useRef, useCallback } from 'react';

interface WebSocketContextType {
  sendMessage: (message: string) => void;
  lastMessage: MessageEvent | null;
  readyState: ReadyState;
  lastJsonMessage: any;
  sendJsonMessage: (message: any) => void;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const [token, setToken] = useState<string>(getStoredValue('token') ?? '');

  useEffect(() => {
    if (!token || token === '') {
      const storedToken = getStoredValue('token');
      if (storedToken) setToken(storedToken);
    }
  }, [token]);

  const messageQueue = useRef<string[]>([]);
  const wsUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}:80/ws?token=${token}`;
  const { sendMessage, lastMessage, readyState, lastJsonMessage } = useWebSocket(
    wsUrl,
    {
      shouldReconnect: (closeEvent) => {
        setToken('');
        setToken(getStoredValue('token') ?? '');
        return true;
      }, // Reconnect on all close events
      reconnectInterval: 3000, // Reconnect every 3 seconds
      reconnectAttempts: Infinity,

      onOpen: () => console.log(`WebSocket connected successfully to: ${wsUrl}`),
      onError: (event) => console.error(`WebSocket error at ${wsUrl}:`, event),
      onClose: (event) => {
        console.warn(`WebSocket closed at ${wsUrl}:`, event);
        if (wsUrl.includes(":3000")) {
          console.error("Unexpected WebSocket attempt to port 3000 detected. Retrying with correct URL.");
        }
      }
    }
  )

  const queueMessage = useCallback((message: string) => {
    if (readyState === ReadyState.OPEN) {
      sendMessage(message);
    } else {
      messageQueue.current.push(message);
    }
  }, [readyState, sendMessage]);

  const queueJsonMessage = useCallback((message: any) => {
    queueMessage(JSON.stringify(message));
  }, [queueMessage]);

  useEffect(() => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        // console.log('WebSocket connecting...');
        break;
      case ReadyState.OPEN:
        // console.log('WebSocket open');
        break;
      case ReadyState.CLOSING:
        // console.log('WebSocket closing...');
        break;
      case ReadyState.CLOSED:
        console.log('WebSocket closed');
        break;
      default:
        break;
    }
  }, [readyState]);

  const value = {
    sendMessage: queueMessage,
    sendJsonMessage: queueJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }
  return context;
};
