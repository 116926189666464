/**
 * 
 * ###### IMPORTANT ######
 * 
 * 1- The unique identifier for each route/object is `routeName`. 
 * ....So, to prevent conflicts or typos, each path can be navigated to using this identifier.
 * 2- Permission are based on `page` and `action` provided for each object.
 * 3- The following object are not NECESSARILY page routes. 
 * ....Meaning, they can be used for permission confirmation, which will be with no routes. 
 * ....For an example, please check `routeName: 'newLayout'`.
 * 4- Breadcrumbs are manageable here in the related field for each object.
 */
import { RouteActions } from "./enums/enums";

export interface AppRoutesModel {
    routeName: string,
    path: string,
    breadcrumb?: string,
    page?: string,
    action?: RouteActions
}

export const appRoutes: AppRoutesModel[] = [
    {
        routeName: 'home',
        path: '/',
        breadcrumb: "Home"
    },
    {
        routeName: 'notifications',
        path: '/notifications',
        breadcrumb: "Notifications List"
    },
    {
        routeName: 'notificationDetails',
        path: '/notifications/notification-details',
        breadcrumb: "Details"
    },
    {
        routeName: 'account',
        path: '/account',
        breadcrumb: "My Account"
    },
    {
        routeName: 'users',
        path: '/users',
        breadcrumb: "Users List",
        page: 'users',
        action: 'list'
    },
    {
        routeName: 'userDetails',
        path: '/users/user-details',
        breadcrumb: "User Details",
        page: 'user',
        action: 'get'
    },
    {
        routeName: 'newUser',
        path: '/users/create-new-user',
        breadcrumb: "Add New User",
        page: 'user',
        action: 'create'
    },
    {
        routeName: 'clients',
        path: '/clients',
        breadcrumb: "Clients List",
        page: 'clients',
        action: 'list'
    },
    {
        routeName: 'clientDetails',
        path: '/clients/client-details',
        breadcrumb: "Client Details",
        page: 'client',
        action: 'get'
    },
    {
        routeName: 'newClient',
        path: '/clients/create-new-client',
        breadcrumb: "Add New Client",
        page: 'client',
        action: 'create'
    },
    {
        routeName: 'permissionGroups',
        path: '/permission-groups',
        breadcrumb: "Permission Groups",
        page: 'permission_groups',
        action: 'list'
    },
    {
        routeName: 'permissionGroupDetails',
        path: '/permission-groups/group-details',
        breadcrumb: "Details",
        page: 'permission_group',
        action: 'get'
    },
    {
        routeName: 'permissions',
        path: '/permission-groups/permissions',
        breadcrumb: "Permissions",
        page: 'permissions',
        action: 'list'
    },
    {
        routeName: 'usersPermission',
        path: '/permission-groups/users-permission',
        breadcrumb: "Users Permissions",
        page: 'user_permissions',
        action: 'list'
    },
    {
        routeName: 'transactions',
        path: '/transactions',
        breadcrumb: "Transactions",
        page: 'transactions',
        action: 'list'
    },
    {
        routeName: 'transactionDetails',
        path: '/transactions/transaction-details',
        breadcrumb: "Details",
        page: 'transaction',
        action: 'get'
    },
    {
        routeName: 'layout',
        path: '/layout',
        breadcrumb: "Layout",
        page: 'layouts',
        action: 'list'
    },
    {
        routeName: 'newLayout',
        path: '',
        breadcrumb: "",
        page: 'layout',
        action: 'create'
    },
    {
        routeName: 'deleteLayout',
        path: '',
        breadcrumb: "",
        page: 'layout',
        action: 'delete'
    },
    {
        routeName: 'layoutDetails',
        path: '/layout/layout-details',
        breadcrumb: "Details",
        page: 'layout',
        action: 'get'
    },
    {
        routeName: 'machines',
        path: '/machines',
        breadcrumb: "Machines List",
        page: 'machines',
        action: 'list'
    },
    {
        routeName: 'machineDetails',
        path: '/machines/machine-details',
        breadcrumb: "Details",
        page: 'machine',
        action: 'get'
    },
    {
        routeName: 'newMachine',
        path: '/machines/add-new-machine',
        breadcrumb: "New Machine",
        page: 'machine',
        action: 'create'
    },
    {
        routeName: 'machineGroups',
        path: '/machine-groups',
        breadcrumb: "Machines Groups",
        page: 'machine_group',
        action: 'list'
    },
    {
        routeName: 'machineGroupDetails',
        path: '/machine-groups/machine-group-details',
        breadcrumb: "Details",
        page: 'machine_group',
        action: 'get'
    },
    {
        routeName: 'machineUsers',
        path: '/machines/machine-users',
        breadcrumb: "Users",
        page: 'machine_users',
        action: 'list'
    },
    {
        routeName: 'loadsList',
        path: '/machines/loads',
        breadcrumb: "Loads list",
        page: 'loads_list',
        action: 'list'
    },
    {
        routeName: 'groupBottles',
        path: '/machine-groups/machine-group-details/group-bottles',
        breadcrumb: "Group Bottles",
        page: 'machine_bottles',
        action: 'list'
    },
    {
        routeName: 'groupBottleDetails',
        path: '/machine-groups/machine-group-details/group-bottles/group-bottle-details',
        breadcrumb: "Details",
        page: 'machine_bottle',
        action: 'get'
    },
    {
        routeName: 'bottlesList',
        path: '/bottles',
        breadcrumb: "Bottles",
        page: 'bottles',
        action: 'list'
    },
    {
        routeName: 'bottleDetails',
        path: '/bottles/bottle-details',
        breadcrumb: "Details",
        page: 'bottle',
        action: 'get'
    },
    {
        routeName: 'newBottle',
        path: '/bottles/add-new-bottle',
        breadcrumb: "New Bottle",
        page: 'bottle',
        action: 'create'
    },
    {
        routeName: 'priceList',
        path: '/price-list',
        breadcrumb: "Price List",
        page: 'prices',
        action: 'list'
    },
    {
        routeName: 'priceDetails',
        path: '/price-list/update-price',
        breadcrumb: "Update Price",
        page: 'price',
        action: 'get'
    },
    {
        routeName: 'penaltyParams',
        path: '/penalty-params-list',
        breadcrumb: "Penalty Params",
        page: 'penalty_params',
        action: 'list'
    },
    {
        routeName: 'penaltyList',
        path: '/penalty-list',
        breadcrumb: "Penalties",
        page: 'penalty',
        action: 'list'
    },
    {
        routeName: 'penaltyDetails',
        path: '/penalty-list/penalty_details',
        breadcrumb: "Details",
        page: 'penalty',
        action: 'get'
    },
    {
        routeName: 'paymentMethods',
        path: '/payment-methods',
        breadcrumb: 'Payment Methods',
        page: 'payment_config',
        action: 'get'
    },
    {
        routeName: 'paymentMethodDetails',
        path: '/payment-methods/method-details',
        breadcrumb: 'Method Details',
        page: 'payment_config',
        action: 'get'
    },
    {
        routeName: 'newPaymentMethod',
        path: '/payment-methods/add-new-method',
        breadcrumb: 'New Method',
        page: 'payment_config',
        action: 'create'
    },
    {
        routeName: 'login',
        path: '/auth'
    },
    {
        routeName: 'forgotPassword',
        path: '/auth/forgot-password'
    },
    {
        routeName: 'resetPassword',
        path: '/auth/reset-password'
    },
]