import { getStoredValue } from "./useStorage";
import { appRoutes, AppRoutesModel } from "./appRoutes";

export const userRole: string = getStoredValue('user') ? JSON.parse(getStoredValue('user') ?? '')?.role : null;
const permissions: string[] = getStoredValue('permissions') ? Object.values(JSON.parse(getStoredValue('permissions') ?? '')) : [];

export const checkPermission = (key: string) => {
    const getAccessLevelAction = (key: string) => appRoutes.find((item: AppRoutesModel) => { return item.routeName === key})?.action ?? '';
    const getAccessedPage = (key: string) => appRoutes.find((item: AppRoutesModel) => { return item.routeName === key})?.page ?? '';

    return ([...permissions].filter((item: string) => {
        return (getAccessedPage(key) && item?.indexOf(`${getAccessLevelAction(key)}_${getAccessedPage(key)}`) > -1)
        // return ((getAccessedPage(key) && (item?.indexOf(getAccessedPage(key)) > -1)) && (getAccessLevelAction(key) && (item?.indexOf(getAccessLevelAction(key)) > -1)))
    })?.length > 0) || userRole === 'superuser';
}